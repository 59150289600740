<template>
    <div v-if="redesSociales != null">
        <div v-if="redesSociales.facebook != null || redesSociales.instagram != null || redesSociales.whatsapp != null" class="float-tab bg-general d-flex flex-column justify-content-center h-auto">
            <div v-if="redesSociales.facebook != null" class="row mx-0 justify-center" @click="nuevaPestana(redesSociales.facebook)">
                <i class="icon-facebook f-30 text-white cr-pointer" />
            </div>
            <div v-if="redesSociales.instagram != null" class="row mx-0 justify-center" @click="nuevaPestana(redesSociales.instagram)">
                <i class="icon-instagram f-30 text-white cr-pointer" />
            </div>
            <div v-if="redesSociales.whatsapp != null" class="row mx-0 justify-center">
                <i class="icon-whatsapp f-30 text-white cr-pointer" />
            </div>
        </div>
    </div>
</template>
<script>
import Landing from '~/services/landing/landing_admin'
export default {
    data(){
        return {
            redesSociales: {}
        }
    },
    computed:{
        idLanding(){
            return Number(this.$route.params.idLanding)
        }
    },
    mounted(){
        this.getRedesSocialesLanding()
    },
    methods: {
        async getRedesSocialesLanding(){
            try{
                const { data } = await Landing.getRedesSociales(this.idLanding)
                this.redesSociales = data.redesSociales

            }catch(error){
                this.error_catch(error)
            }
        },
        nuevaPestana(item){
            let route= item
            window.open(route)
        }
    }
}
</script>
<style lang="scss" scoped>


.float-tab{
    width: 46px;
    right: 33px;
    color: #FFFFFF;
    height: 148px;
    right: 0px;
    position: absolute;
    border-radius: 12px 0px 0px 12px;
    top: 391px;

}

.space-screenshot{
    position: absolute;
    width: 100%;
    height: 459px;
    top: 0px;
}
</style>
